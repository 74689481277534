import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '../i18n';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null,
        center: null,
        modulesArray: [],
        CloverRetry: 0,
        cloverExternalId: '',
        cloverConnector: null,
        cloverPaymentStatus: '',
        clover_payment_status: null,
        refundpaymentresponse: null,
        defaultCloverConnectorListener: null,
        saleresponse: null,
        cloverPaymentButton: null,
        cloverPaymentDone: false,
        layout: 'app',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'light',
        locale: null,
        cart: [],
        payments: [],
        customer: null,
        menu_style: 'horizontal',
        layout_style: 'full',
        countryList: [
            { code: 'zh', name: 'Chinese' },
            { code: 'da', name: 'Danish' },
            { code: 'en', name: 'English' },
            { code: 'fr', name: 'French' },
            { code: 'de', name: 'German' },
            { code: 'el', name: 'Greek' },
            { code: 'hu', name: 'Hungarian' },
            { code: 'it', name: 'Italian' },
            { code: 'ja', name: 'Japanese' },
            { code: 'pl', name: 'Polish' },
            { code: 'pt', name: 'Portuguese' },
            { code: 'ru', name: 'Russian' },
            { code: 'es', name: 'Spanish' },
            { code: 'sv', name: 'Swedish' },
            { code: 'tr', name: 'Turkish' },
        ],
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = value || 'en';
            i18n.locale = value;
            localStorage.setItem('i18n_locale', value);
            state.locale = value;
        },
        setModulesArray(state, modulesArray) {
            state.modulesArray = modulesArray;
        },
        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'light';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } else if (value == 'system') {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        },
        user(state, user) {
            state.user = user;
        },
        center(state, center) {
            state.center = center;
        },
        cloverConnector(state, cloverConnector) {
            state.cloverConnector = cloverConnector;
        },
        cart(state, cart) {
            state.cart = cart;
        },
        cloverPaymentStatus(state, CloverPaymentStatus) {
            state.cloverPaymentStatus = CloverPaymentStatus;
        },
        clover_payment_status(state, clover_payment_status) {
            state.clover_payment_status = clover_payment_status;
        },
        cloverPaymentButton(state, CloverPaymentButton) {
            state.cloverPaymentButton = CloverPaymentButton;
        },
        cloverPaymentDone(state, CloverPaymentDone) {
            state.cloverPaymentDone = CloverPaymentDone;
        },
        refundpaymentresponse(state, refundpaymentresponse) {
            state.refundpaymentresponse = refundpaymentresponse;
        },
        defaultCloverConnectorListener(state, defaultCloverConnectorListener) {
            state.defaultCloverConnectorListener = defaultCloverConnectorListener;
        },
        saleresponse(state, saleresponse) {
            state.saleresponse = saleresponse;
        },
    },
    getters: {
        layout(state) {
            return state.layout;
        },
        user(state) {
            return state.user;
        },
        center(state) {
            return state.center;
        },
        cloverConnector(state) {
            return state.cloverConnector;
        },
        cloverPaymentStatus(state) {
            return state.cloverPaymentStatus;
        },
        clover_payment_status(state) {
            return state.clover_payment_status;
        },
        defaultCloverConnectorListener(state) {
            return state.defaultCloverConnectorListener;
        },
        cloverPaymentButton(state) {
            return state.cloverPaymentButton;
        },
        cloverPaymentDone(state) {
            return state.cloverPaymentDone;
        },
        refundpaymentresponse(state) {
            return state.refundpaymentresponse;
        },
        saleresponse(state) {
            return state.saleresponse;
        },
        cart(state) {
            return state.cart;
        },
        setModulesArray(state) {
            return state.modulesArray;
        },
    },
    actions: {
        user(context, user) {
            context.commit('user', user);
        },
        center(context, center) {
            context.commit('center', center);
        },
        cloverConnector(context, cloverConnector) {
            context.commit('cloverConnector', cloverConnector);
        },
        cloverPaymentStatus(context, cloverPaymentStatus) {
            context.commit('cloverPaymentStatus', cloverPaymentStatus);
        },
        defaultCloverConnectorListener(context, defaultCloverConnectorListener) {
            context.commit('defaultCloverConnectorListener', defaultCloverConnectorListener);
        },
        clover_payment_status(context, clover_payment_status) {
            context.commit('clover_payment_status', clover_payment_status);
        },
        cloverPaymentButton(context, cloverPaymentButton) {
            context.commit('cloverPaymentButton', cloverPaymentButton);
        },
        cloverPaymentDone(context, cloverPaymentDone) {
            context.commit('cloverPaymentDone', cloverPaymentDone);
        },
        refundpaymentresponse(context, refundpaymentresponse) {
            context.commit('refundpaymentresponse', refundpaymentresponse);
        },
        saleresponse(context, saleresponse) {
            context.commit('saleresponse', saleresponse);
        },
        cart(context, cart) {
            context.commit('cart', cart);
        },
        setModulesArray(context, modulesArray) {
            context.commit('setModulesArray', modulesArray);
        },
    },
    modules: {},
});
