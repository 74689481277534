<template>
  <footer class="footer">
    <b-nav>
      <router-link to="/customer/home">
        <div>
          <svg height="2em" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M27.5 14.2374V22.625C27.5 25.3125 25.4 27.4999 22.8125 27.4999H18.125V21.5C18.125 20.6999 17.475 20.0499 16.675 20.0499H13.325C12.525 20.0499 11.8875 20.6999 11.8875 21.5V27.4999H7.1875C4.6 27.4999 2.5 25.3125 2.5 22.625V14.2374C2.5 12.8 3.1125 11.4374 4.1625 10.5L11.975 3.64995C13.7375 2.11245 16.275 2.11245 18.025 3.64995L25.8375 10.5C26.9 11.4374 27.5 12.8 27.5 14.2374Z"
              fill="white" />
          </svg>

          <a>
            Home
          </a>
        </div>
      </router-link>
      <router-link to="/customer/facilitiesbooking">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512">
            <path
              d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"
              fill="white" />
          </svg>
          <a>Booking
          </a>
        </div>
      </router-link>
      <router-link to="/customer/access">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 576 512">
            <path
              d="M512 80c8.8 0 16 7.2 16 16v32H48V96c0-8.8 7.2-16 16-16H512zm16 144V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V224H528zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H248z"
              fill="white" />
          </svg>
          <a> Access
          </a>
        </div>
      </router-link>
      <router-link to="/customer/account">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512">
            <path
              d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"
              fill="white" />
          </svg>
          <a> Account
          </a>
        </div>
      </router-link>
    </b-nav>
  </footer>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: linear-gradient(180deg, #0a3ecb 0%, rgba(74, 187, 238, 0.98) 100%);
  text-align: center;
  box-sizing: border-box;
}

/* Adjust footer styles for mobile view */
@media only screen and (max-width: 600px) {
  footer {
    height: 60px;
  }
}

ul.nav {
  text-align: center;
  padding: 0.4rem;
}

ul div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  margin-left: auto;
  margin-right: auto;
  color: white;
  font-weight: 500;
}

a:hover {
  margin-left: auto;
  margin-right: auto;
  color: white;
  font-weight: 500;
}
</style>